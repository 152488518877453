 <template>
  <div>
    <CModal color="danger" :show="deleteModal">
      <template v-slot:header>
        <h5 class="modal-title">Delete Customer</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal(deleteModal)"
        >
          ×
        </button>
      </template>
      Do you want to delete "<b>{{ customer ? customer.first_name + " " + customer.last_name : null}}</b>"?
      <template v-slot:footer>
        <CButton color="secondary" @click="closeModal(deleteModal)"
          >Cancel</CButton
        >

        <CButton color="secondary" class="px-4" disabled v-if="isSaving">
          <CSpinner grow size="sm" /> Deleting..
        </CButton>
        <CButton color="danger" @click="deleteCustomer(customer)" v-else>
          Delete Customer
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CustomerDelete",
  computed: {
    ...mapState({
      deleteModal: (state) => state.customers.deleteModal,
      customer: (state) => state.customers.deleteCustomer,
      isSaving: (state) => state.customers.isSaving,
    }),
  },
  methods: {
    closeModal(bool) {
      this.$store.commit("customers/DELETE_MODAL", { bool: bool, customer: null });
    },
    deleteCustomer(customer) {
      this.$store.dispatch("customers/destroyCustomer", { 
        customer: customer
      });
    },
  },
};
</script>